<template>
  <div class="sg-web-content-2">
    <img
      src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/star.png"
      class="sg-w100"
    />
    <el-row class="sg-border-bottom">
      <el-col :sm="{ span: 16, offset: 4 }">
        <div class="sg-flex-center">
          <div
            class="sg-space-between sg-bold sg-gray-b1 sg-font-14px"
            style="width: 1152px"
          >
            <div class="sg-flex-center sg-gray-b1">
              <i class="el-icon-s-home sg-orange"></i>
              <i class="el-icon-caret-right sg-pad" style="padding: 0 11px"></i>
              <div class="sg-gray-b1">关于正启之星</div>
              <i class="el-icon-caret-right sg-pad" style="padding: 0 11px"></i>
              <div>{{ tabName }}</div>
            </div>
            <div class="sg-flex-center sg-gray-6a sg-bold sg-font-14px">
              <div
                :class="{
                  'sg-tab sg-pointer sg-hover sg-nav-ani': true,
                  'sg-selected': tab == item.key,
                }"
                :style="{
                  'margin-left': '30px',
                  'line-height': scrollNavDown ? '80px' : '110px',
                  height: scrollNavDown ? '82px' : '112px',
                }"
                v-for="(item, index) in tabs"
                :key="index"
                @click="goTab(item.key)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row v-show="tab == 'intro'" style="padding-bottom: 82px">
      <transition name="sg-fade-in-2">
        <div class="sg-flex-center">
          <div v-show="tab == 'intro'" class="sg-flex" style="width: 1152px">
            <div style="padding: 85px 156px 0 0">
              <div
                class="sg-gray-d9d8d8 sg-font-25px sg-font-hel"
                style="width: 214px"
              >
                ZHENGQI STAR
              </div>
              <div class="sg-red sg-font-20px" style="padding-top: 18px">
                正&nbsp;启&nbsp;之&nbsp;星
              </div>
            </div>
            <div style="padding: 87px 0 62.5px 39px" class="sg-border-left">
              <h2 class="sg-red sg-font-23px sg-bold">
                正启之星(北京)企业管理有限公司
              </h2>
              <div
                class="sg-gray-d9d8d8 sg-font-28px sg-font-hel"
                style="padding: 16px 0 32.5px 0"
              >
                ABOUT ZHENGQISTAR
              </div>
              <div
                class="sg-gray-787777 sg-font-13px sg-text-justify"
                style="line-height: 20px"
              >
                <div>
                  正启之星（北京）企业管理有限公司，是一家以互联网技术为支撑，以产业园区服务为核心，集财税服务、医疗服务、建筑服务、人力资源服务和职业培训为一体的产业园区创新运营商。
                </div>

                <div style="margin-top: 21px">
                  在新经济发展趋势下，凭借企业在科技、财税、人力资源领域多年服务经验，我们顺势而为，陆续推出“税之蛙”、“众蝌云”、“安之慧”、“程宝建服”四大服务品牌，并由此不断向现代服务行业、医疗行业、建筑行业等领域开拓、发展，打造出新经济模式下综合性总部经济服务平台，以专业化发展理念不断优化营商环境，赋能企业快速发展。
                </div>

                <div style="margin-top: 21px">
                  旗下四大服务品牌，针对不同行业提供不同服务。其中“税之蛙”专注为企业和个人提供综合财税规划服务，“众蝌云”为企业提供高效便捷的灵活用工与人力资源外包服务，“安之慧”是国内生命健康领域领先的众包服务平台，“程宝建服”则为建筑行业提供劳务、机械租赁、原材料交易等一站式服务及财税解决方案。
                </div>

                <div style="margin-top: 21px">
                  从业十余年，目前正启之星业务已遍布全国，服务客户上万家。
                </div>

                <div style="margin-top: 21px">
                  未来，我们将始终致力以更专业、优质的服务，赋能产业园区，助力企业发展，助推新经济加速发展。
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </el-row>
    <transition name="sg-fade-in-2">
      <div v-show="tab == 'dev'">
        <!-- <el-row>
          <el-col :sm="{ span: 10, offset: 7 }">
            <div data-v-d690cff2="" class="sg-flex"><div data-v-d690cff2=""><div data-v-d690cff2="" class="sg-pad-lr sg-margin-lg-bottom sg-margin-top-md sg-red-c73437 sg-font-40px sg-red-border-right"> 2023 </div></div><div data-v-d690cff2="" class="sg-border-left sg-flex-grow sg-year-content"><div data-v-d690cff2="" class="sg-desc" style="padding: 34px;"><span>推出税务体检服务</span><br>摸排企业整体情况，判断风险点，出具诊断意见<br>帮企业深入了解自身税务状况、可适用税惠政策、税务健康风险及权益保障途径<br>为企业防范风险、健康发展提供有效指引</div></div></div>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :sm="{ span: 24 }">
            <div>
              <div>
                <div
                  :class="{
                    'sg-fade-in sg-flex-center': true,
                    'sg-first-year': index == 0,
                  }"
                  v-for="(item, index) in dev_list"
                  :key="index"
                >
                  <div style="width: 1266px" class="sg-flex">
                    <div
                      :style="{
                        'padding-left': index == 0 ? '294px' : '296px',
                      }"
                    >
                      <div
                        class="sg-red-c73437 sg-font-47px sg-font-hel-regular sg-bold sg-red-border-right sg-title"
                        style="
                          padding-right: 42px;
                          margin-top: 32px;
                          margin-right: -2px;
                          z-index: 1000;
                          position: relative;
                          width: 149px;
                          box-sizing: border-box;
                        "
                      >
                        {{ item.year }}
                      </div>
                    </div>
                    <div class="sg-border-left sg-flex-grow sg-year-content">
                      <div
                        v-html="item.desc"
                        :style="{
                          padding:
                            index == 8
                              ? '29px 34px 84px 0'
                              : '29px 34px 34px 0',
                          'margin-left': '37px',
                          'line-height': '22px',
                        }"
                        :class="{
                          'sg-desc': true,
                          'sg-dash-border': index > 1,
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </transition>
    <transition>
      <div v-show="tab == 'culture'">
        <transition appear name="sg-fade-in-0p2">
          <el-row v-show="tab == 'culture'">
            <el-col :sm="{ span: 16, offset: 4 }">
              <div class="sg-flex-center">
                <div style="width: 1152px">
                  <transition name="sg-fade-up-0p6-new">
                    <div
                      style="
                        font-size: 23px;
                        color: #6a6a6a;
                        margin-top: 126px;
                        letter-spacing: 7.2px;
                        font-family: 'Microsoft YaHei';
                      "
                      v-show="tab == 'culture'"
                    >
                      正启之星（北京）企业管理有限公司
                    </div>
                    <!-- <img src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/cul-title.png" style="width: 412px; margin-top: 126px;margin-bottom: 94px;"/> -->
                  </transition>
                  <transition name="sg-fade-up-0p8-new">
                    <div v-show="tab == 'culture'">
                      <div
                        style="
                          font-size: 15px;
                          color: #0265a1;
                          font-weight: bold;
                          margin: 12px 0 12px 0;
                        "
                        class="sg-font-hum"
                      >
                        ZHENGQI STAR (BEIJING) ENTERPRISE MANAGEMENT CO., LTD
                      </div>
                      <div
                        style="
                          width: 26px;
                          height: 4px;
                          background-color: #0265a1;
                          margin-bottom: 93px;
                        "
                      ></div>
                    </div>
                  </transition>
                </div>
              </div>
            </el-col>
          </el-row>
        </transition>

        <div
          style="
            background-image: url('https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/company-cul-bg-4.jpg');
            background-size: cover;
          "
        >
          <el-row>
            <el-col :sm="{ span: 16, offset: 4 }">
              <div class="sg-flex-center">
                <div
                  style="
                    color: white;
                    font-size: 35px;
                    font-weight: bold;
                    margin-top: 182px;
                    margin-bottom: 253px;
                    width: 1152px;
                  "
                >
                  <div class="sg-space-between" style="align-items: flex-start">
                    <transition appear name="sg-fade-left-0p12-new">
                      <img
                        v-show="tab == 'culture'"
                        src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/serve-cul.png"
                        style="margin-bottom: 29px"
                      />
                    </transition>
                    <div style="width: 406px; position: relative">
                      <div
                        style="
                          position: absolute;
                          z-index: 2;
                          padding: 50px 16px 24px 22px;
                          top: -374px;
                          width: 406px;
                          height: 588px;
                          box-sizing: border-box;
                          background-image: url('https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/cul-bg-3.png');
                        "
                      >
                        <transition
                          v-for="(item, index) in cul_list"
                          :key="index"
                          :name="'sg-fade-up-0p' + (index + 15) + '-new'"
                        >
                          <div class="sg-flex" v-show="tab == 'culture'">
                            <img
                              :src="item.img"
                              class="sg-img-xs sg-h100"
                              :style="{
                                width: '23px',
                                'padding-right': '15px',
                              }"
                            />
                            <div>
                              <div
                                class="sg-white sg-font-13px sg-bold"
                                style="margin-bottom: 7px"
                              >
                                {{ item.title }}
                              </div>
                              <div
                                class="sg-white sg-font-13px"
                                style="font-weight: lighter; line-height: 20px"
                                v-html="item.desc"
                              ></div>
                              <div
                                class="sg-flex"
                                style="padding: 24.5px 0 28px 0"
                              >
                                <!-- <div
                                style="
                                  width: 5px;
                                  height: 5px;
                                  background-color: #d52e2e;
                                "
                              ></div> -->
                                <div
                                  style="
                                    width: 38px;
                                    height: 3px;
                                    background-color: white;
                                  "
                                ></div>
                              </div>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>

                  <div class="sg-relative">
                    <transition
                      v-for="n in 10"
                      :key="n"
                      :name="'sg-fade-up-0p4-new'"
                    >
                      <img
                        :src="
                          'https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/new-cul-' +
                          n +
                          '.png'
                        "
                        :key="n"
                        class="sg-w100"
                        style="
                          position: absolute;
                          left: 0;
                          top: 35%;
                          width: 215px;
                        "
                        v-show="tab == 'culture'"
                      />
                    </transition>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </transition>
    <div v-show="tab == 'core'">
      <el-row>
        <el-col :sm="{ span: 16, offset: 4 }">
          <div class="sg-flex-center">
            <div
              class="sg-pad-top-md sg-pad-bottom-md sg-pad-right-md sg-gray-cbc4c0 sg-font-16px"
              style="width: 1152px; box-sizing: border-box; padding-left: 50px"
            >
              <div>{{ core_item.zh }}</div>
              <div style="margin-top: 9px" class="sg-font-18px">
                {{ core_item.en }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div
        class="sg-core-bg"
        id="sg-core-box"
        style="z-index: 1003; position: relative; height: 100vh"
      >
        <el-row>
          <el-col
            :sm="{ span: 24 }"
            style="margin-top: calc((100vh - 520px) / 2); padding-bottom: 223px"
          >
            <div style="width: 1165px; margin: auto">
              <div class="sg-relative" @mousewheel.stop.prevent="mouseScroll">
                <el-carousel
                  height="520px"
                  :autoplay="false"
                  :arrow="'never'"
                  @change="caChange"
                  ref="myCarousel"
                  indicator-position="none"
                >
                  <el-carousel-item
                    v-for="(item, index) in core_list"
                    :key="index"
                  >
                    <div style="position: relative">
                      <img
                        src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/line2.png"
                        style="
                          width: 132px;
                          position: absolute;
                          right: 24%;
                          top: 19%;
                          z-index: 10;
                        "
                      />
                      <img
                        :src="item.img"
                        style="width: 838px; height: 447px"
                      />
                      <div
                        class="sg-white-bg sg-core-txt-box sg-flex"
                        v-bind:style="{
                          borderRadius: '14px',
                          padding: item.pad,
                          height: '396px',
                          boxSizing: 'border-box',
                          width: '652px',
                          'box-shadow':
                            '7px 2px 23px 1px rgba(211,211,211,0.19)',
                        }"
                      >
                        <div>
                          <transition name="sg-fade-down-0p2">
                            <div
                              class="sg-gray-44 sg-bold"
                              style="font-size: 24.6px"
                              v-show="core_index == index"
                            >
                              {{ item.zh }}
                            </div>
                          </transition>
                          <transition name="sg-fade-down-0p2">
                            <h4
                              v-show="core_index == index"
                              class="sg-gray-b3 sg-font-hum"
                              style="padding: 12px 0 22px 0; font-size: 16.4px"
                            >
                              {{ item.en }}
                            </h4>
                          </transition>
                          <transition name="sg-fade-up-0p4">
                            <div
                              v-html="item.txt"
                              class="sg-gray-44"
                              v-show="core_index == index"
                              :style="{
                                'font-size': '12px',
                                'line-height': item.lineH,
                                'padding-bottom': '28px',
                              }"
                            ></div>
                          </transition>
                          <transition name="sg-fade-up-0p4">
                            <div
                              class="sg-index-line"
                              v-show="core_index == index"
                            ></div>
                          </transition>
                        </div>
                        <!-- <i class="el-icon-back sg-red sg-bold"></i> -->
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <div class="sg-pox-btn" style="top: 63%">
                  <div
                    :class="{ 'sg-pox-prev': true, on: arrow == 'right' }"
                    @mouseenter="arrow = 'right'"
                    @mouseleave="arrow = 'left'"
                    tabindex="0"
                    role="button"
                    aria-label="Previous slide"
                    style="top: 55%"
                    @click="prevCard"
                  ></div>
                  <div
                    @mouseenter="arrow = 'left'"
                    :class="{ 'sg-pox-next': true, on: arrow == 'left' }"
                    tabindex="0"
                    role="button"
                    aria-label="Next slide"
                    @click="nextCard"
                  ></div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-show="tab == 'serve'">
      <el-row>
        <el-col :sm="{ span: 16, offset: 4 }">
          <div class="sg-flex-center">
            <div
              class="sg-pad-top-md sg-pad-bottom-md sg-gray-cbc4c0"
              style="width: 1152px; box-sizing: border-box; padding-left: 50px"
            >
              <div class="sg-font-16px">服务轨迹</div>
              <div class="sg-font-18px" style="padding: 8px 0 10px 0">
                SERVICE TRACKS
              </div>
              <div style="width: 25px; height: 4px; background: #c31a1f"></div>
            </div>
          </div>
        </el-col>
      </el-row>
      <transition name="sg-fade-in-2">
        <el-row v-show="tab == 'serve'">
          <el-col :sm="{ span: 16, offset: 4 }">
            <div class="sg-flex-center">
              <img
                src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/map-2.png"
                class="sg-w100"
                style="width: 1152px; padding-left: 50px"
              />
            </div>
          </el-col>
        </el-row>
      </transition>
      <!-- <el-row>
        <el-col :sm="{ span: 14, offset: 5 }">
          <div class="sg-gray-cbc4c0" style="padding: 44px 0 35px 35px">
            <div class="sg-font-18px">服务客户</div>
            <div class="sg-font-20px" style="padding: 8px 0 10px 0">
              SERVE CUSTOMERS
            </div>
            <div style="width: 25px; height: 4px; background: #c31a1f"></div>
          </div>
        </el-col>
      </el-row>
      <transition name="sg-fade-in-2">
        <el-row v-show="showServe2">
          <el-col :sm="{ span: 14, offset: 5 }">
            <img
              src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/custom.png"
              class="sg-w100"
              style="margin-bottom: 180px"
            />
          </el-col>
        </el-row>
      </transition> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Tempalte",
  props: {},
  components: {},
  data: () => {
    return {
      tab: "",
      arrow: "left",
      tabs: [
        { name: "企业介绍", key: "intro" },
        { name: "发展历程", key: "dev" },
        { name: "企业文化", key: "culture" },
        { name: "核心优势", key: "core" },
        { name: "服务客户", key: "serve" },
      ],
      core_index: -1,
      core_list: [
        {
          zh: "资源优势",
          en: "RESOURCE ADVANTAGE",
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/res-ad-2.png",
          pad: "63px 158px 10px 108px",
          lineH: "21px",
          txt: "<div style='letter-spacing: 0.5px;'>拥有全国各地一手政府资源，直接对接园区运营服务商，每个园区均配备高效落地服务团队，有力保障企业顺利落地和后续经营中各项服务需求对接。</div><div style='margin-top: 6px'>拥有全国可落地资源，包括人力资源服务、财税服务、园区保障服务等。</div><div style='margin-top: 6px'>目前与北京、上海、江苏、山东、安徽、江西、湖南、湖北、重庆、海南、云南等十几个地方政府签署战略合作协议，与政府直接合作，并建立了深厚合作关系。</div>",
        },
        {
          zh: "服务优势",
          en: "SERVICE ADVANTAGES",
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/serve-ad.png",
          pad: "97px 168px 10px 108px",
          lineH: "25px",
          txt: "研究全国各地产业园区政策方针，与当地政府紧密合作，为园区提供先进企业引进、前期规划、人才培养和输出等服务，以及为园区企业提供从落地到后续发展全程的日常财税规划、上市辅导、融通资金等一站式产业链综合服务。",
        },
        {
          zh: "专业优势",
          en: "PROFESSIONAL ADVANTAGES",
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/pro-ad-3.png",
          pad: "109px 167px 10px 109px",
          lineH: "25px",
          txt: "企业围绕产业园区运营，依托多年服务经验，持续深耕五大服务领域，集会计师、税务师、律师专家团队、培训师等各行业优秀人才，为园区及园区企业发展提供优质、专业的一体化服务。",
        },
        {
          zh: "技术优势",
          en: "TECHNICAL ADVANTAGES",
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/tech-ad.png",
          lineH: "25px",
          txt: "以先进互联网“SaaS”服务为支撑，搭建多业务板块线上服务云平台，为客户提供高效、便捷的综合服务。",
          pad: "122px 175px 10px 108px",
        },
      ],
      cul_list: [
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/war_3.png",
          width: "23px",
          height: "22px",
          title: "战略定位",
          desc: "以技术为支撑，以一体化服务为核心的新经济产业园区<br><div>创新运营商</div>",
        },
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/dev_3.png",
          width: "23px",
          height: "30px",
          title: "团队作风",
          desc: "诚信正直  勇于创新  专业高效  客户至上",
        },
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/team_3.png",
          width: "28px",
          height: "26px",
          title: "企业精神",
          desc: "不断提升技术水平，为产业园区提供普惠价值服务",
        },
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/company_3.png",
          width: "23px",
          height: "22px",
          title: "发展使命",
          desc: "以综合性、一体化服务，赋能产业园区，加速新经济发展",
        },
        {
          img: "https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/serve_3.png",
          width: "23px",
          height: "20px",
          title: "服务宗旨",
          desc: "利用全国优质一手政府资源，服务园区及企业，带动就业，促进经济发展",
        },
      ],
      dev_list: [
        {
          year: "2023",
          desc: "<span></span>",
        },
        {
          year: 2023,
          desc: "<span>推出税务体检服务</span><br>摸排企业整体情况，判断风险点，出具诊断意见<br>帮企业深入了解自身税务状况、可适用税惠政策、税务健康风险及权益保障途径<br>为企业防范风险、健康发展提供有效指引",
        },
        {
          year: 2022,
          desc: "<span>布局职业培训、人才定制化培养行业</span><br>打造产教融合就业实训基地，推出数字经济专业人才培养课程<br>提供报名、试学、实训、就业落实一条龙服务<br>积极开展乡村振兴及家政兴农人才培养",
        },
        {
          year: 2021,
          desc: "<span>打造建筑行业合规云平台</span><br>整合旗下三大建筑服务平台，推出“建筑行业众包系统”<br>为行业提供劳务、机械租赁、原材料交易等一站式服务<br><br><b>进军医疗服务领域</b><br>打造“安之慧SAAS平台”<br>专业提供医疗科研技术、CRO转包、医药与器械公司推广等服务",
        },
        {
          year: 2020,
          desc: "<span>推出“众蝌云”灵活用工品牌</span><br>顺应灵活用工市场快速发展趋势<br>打造“零工经济”下多元化用工服务平台<br>为客户提供线上灵活用工、人力资源外包等服务",
        },
        {
          year: 2019,
          desc: "<span>创建“税之蛙”品牌</span><br>以科技为驱动，利用优质园区资源和税收优惠政策，<br>为企业和个人定制合理、优质税务规划方案，解决税负压力",
        },
        {
          year: 2018,
          desc: "<span>根据客户个性化财税需求，推出一对一定制化财税服务</span><br>针对企业突出税务痛点进行税务规划方案制定<br>实质解决企业税务问题",
        },
        {
          year: 2013,
          desc: "<span>创建标准化服务流程</span><br>推进事项清单标准化、办理流程标准化、服务规范标准化<br>以标准化提升客户服务体验，提高服务质量和效率",
        },
        {
          year: 2011,
          desc: "<span>创立企业</span><br>为客户提供专业财税规划服务，财税咨询、注册代理、会计服务、上市指导、融通资金、 <br>股权架构与激励、减持退出等",
        },
      ],
      showServe2: false,
      core_index_save: 0,
    };
  },
  computed: {
    ...mapState({
      scrollTop: (state) => state.scrollTop,
      scrollNavDown: (state) => state.scrollNavDown,
      scrollY: (state) => state.scrollY,
    }),
    core_item() {
      if (this.core_index < 0) return this.core_list[0];
      return this.core_list[this.core_index];
    },
    tabName() {
      let tabs = this.tabs;
      for (let i = 0; i < tabs.length; ++i) {
        if (tabs[i].key == this.tab) return tabs[i].name;
      }
      return tabs[0].name;
    },
  },
  watch: {
    scrollTop(val) {
      if (val > 400) this.showServe2 = true;
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams) => {
        this.tab = toParams.tab ? toParams.tab : "intro";
        // console.log(this.tab);
      }
    );
  },
  mounted() {
    let that = this;

    let tab = that.$route.params.tab ? that.$route.params.tab : "intro";
    that.core_index = 0;
    setTimeout(function () {
      that.goTab(tab);
    }, 500);
    // this.goCoreTab(0);
  },
  methods: {
    goCore() {
      // let coreBox = document.getElementById('sg-core-box')

      // document.getElementById("sg-core-box").scrollIntoView(true);
      // window.scrollTo(0, document.getElementById('sg-core-box').scrollTop)
      // console.log('---')
      let that = this;
      this.$nextTick(() => {
        console.log(document.getElementById("sg-core-box").offsetTop);
        window.scrollBy(
          that.scrollY,
          document.getElementById("sg-core-box").offsetTop
        );
      });
    },
    goTab(tab) {
      this.tab = tab;
      if (this.tab == "core") {
        if (this.$route.path == "/star/core") {
          this.core_index = this.core_index_save;
          this.goCore();
        } else {
          window.location = "/star/core";
        }
      } else {
        this.core_index_save = this.core_index;
        this.core_index = -1;
      }
    },
    imgChange(index) {
      this.core_index = index;
    },
    mouseScroll(e) {
      let th = 100;
      if (e.deltaY > th) {
        this.nextCard();
      } else if (e.deltaY < 0 - th) {
        this.prevCard();
      }
    },
    prevCard() {
      this.$refs.myCarousel.prev();
    },
    nextCard() {
      this.$refs.myCarousel.next();
    },
    caChange(index) {
      this.core_index = index;
    },
    goCoreTab(index) {
      this.core_index = index;
      this.$refs.myCarousel.setActiveItem(index);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-first-year {
  .sg-title {
    color: transparent;
    border-right: 3px solid transparent;
    height: 50px;
    margin: 0 !important;
  }
  .sg-year-content {
    height: 50px;
  }
}
.sg-tab:hover,
.sg-tab.sg-selected {
  color: $red;
  border-bottom: 2px solid $red;
}
.sg-index-line {
  width: 50px;
  height: 3px;
  background: $red;
}
.sg-red-border-right {
  border-right: 3px solid #c73437;
}
// .sg-desc {
//   color: #545454;
// }
.sg-dash-border {
  border-top: 1px dashed #a5a5a5;
}
.sg-hover-gray:hover {
  background: $gray-4;
}
.sg-core-bg {
  background-image: url("https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/core-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.sg-core-txt-box {
  width: 400px;
  position: absolute;
  right: 0;
  top: 28%;
}
</style>
<style lang="scss">
.sg-year-content {
  color: #545454;
  font-size: 14px;
  line-height: 24px;
  span {
    font-weight: bold;
    color: #444444;
  }
}
</style>
